<template>
    <div class="container-fluid pb-5" style="background:#E7E3DE">
            <div class="row" >
                <div class="container">
                    <div class="row">
                        <div class="col-12 my-5">
                            <center><h4>Nuestro proceso de aprendizaje</h4></center>
                        </div>
                        <div class="col-12 col-md-5">
                            <img src="@/assets/img/tmt-model2.png" class="img-fluid" alt="" title="">
                        </div>
                        <div class="col-12 col-md-7">
                            <b>Explora</b>
                            <p>Explora nuestro catálogo de cursos mediante videos de presentación donde encontraras el objetivo de cada curso.</p>
                            <b>Selecciona</b>
                            <p>Selecciona y adquiere el curso de tu interés, tendrás que crear una cuenta para mantener el registro de tu aprendizaje.</p>
                            <b>Aula virtual</b>
                            <p>El curso se desarrolla en un ambiente virtual donde se explican los temas a través de multimedia, donde vivirás la experiencia de nuestro proceso de convertir conceptos complejos en palabras sencillas. </p>
                            <b>Proceso de evaluación</b>
                            <p>Tendrás 30 días para finalizar el programa en el que te inscribiste<br>
                            La calificación mínima para acreditar el proceso es de 85%.
                            </p>
                            <b>Acreditación</b>
                            <p>Una vez llegado a este punto tu proceso de aprendizaje habrá sido evaluado y recibirás el certificado de acreditación, y si así lo deseas recibirás un reconocimiento público en nuestras redes sociales; El certificado que recibes tiene una codificación para que pueda ser verificado en nuestro sistema.</p>
                        </div>

                    </div>
                </div>
            </div>
    </div>
</template>
<style scoped>
p{font-size:14px}
</style>
  <script>
  export default {
    name: 'Tmtmodel',
  }
  </script>
