<template>
    <div class="d-flex flex-column min-vh-100">
        <Header :profile="profile"/>
        <div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel">
            <ol class="carousel-indicators">
                <li data-target="#carouselExampleCaptions" data-slide-to="0" class="active"></li>
            </ol>
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img src="@/assets/img/bg.jpg" class="d-block w-100" alt="..." />
                    <div class="carousel-caption d-none d-md-block">
                        <h4 style="margin-bottom:30px">REACH YOUR POTENCIAL</h4>
                        <p><router-link class="btn btn-lg btn-black mr-5" to="/cursos">Nuestros Cursos</router-link> <router-link class="btn btn-lg btn-golden" to="/acerca-de">¿Qué es TMT?</router-link></p>
                    </div>
                </div>
            </div>
            <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>
        <Courses />
        <Tmtmodel />
        <!--
        <div class="container-fluid mb-5" style="">
            <div class="row" >
                <div class="container">
                    <div class="row">
                        <div class="col-12 my-5">
                            <center><h5>Nuestro proceso de aprendizaje</h5></center>
                        </div>
                        <div class="col-12 mb-4">
                            <center><img src="@/assets/img/tmt-model2.png" alt="" title=""></center>
                        </div>
                        <div class="col-2">
                            <div class="shadow-sm p-3 mb-5 bg-body rounded text-center" style="background:#E7E3DE">
                                <i class="fa fa-search"></i>
                            Explora
                            </div>
                        </div>
                         <div class="col-2">
                            <div class="shadow-sm p-3 mb-5 bg-body rounded text-center" style="background:#E7E3DE">
                             <i class="fa fa-hand-pointer"></i> Selecciona
                            </div>
                        </div>
                         <div class="col-2">
                            <div class="shadow-sm p-3 mb-5 bg-body rounded text-center" style="background:#E7E3DE">
                             <i class="fa fa-laptop"></i>Aula Virtual
                            </div>
                        </div>
                         <div class="col-2">
                            <div class="shadow-sm p-3 mb-5 bg-body rounded text-center" style="background:#E7E3DE">
                             <i class="fa fa-file-alt"></i> Evaluación + proyectos
                            </div>
                        </div>
                         <div class="col-2">
                            <div class="shadow-sm p-3 mb-5 bg-body rounded text-center" style="background:#E7E3DE">
                             <i class="fa fa-user"></i>Coach
                            </div>
                        </div>
                         <div class="col-2">
                            <div class="shadow-sm p-3 mb-5 bg-body rounded text-center" style="background:#E7E3DE">
                             <i class="fa fa-graduation-cap"></i>Acreditación
                            </div>
                        </div>
                        <div class="col-7">
                            <b>Explora</b>
                            <p>Explora nuestros catálogos de cursos mediante las fichas técnicas donde encontraras los objetivos de cada curso y videos de presentación de cursos </p>
                            <b></b>
                            <p>Selecciona y adquiere el curso de tu interés</p>
                            <b></b>
                            <p>El curso se desarrollara primero en un ambiente virtual donde se explicaran los temas a través de multimedia</p>
                            <b></b>
                            <p>Cada curso a parte de ejercicios interactivos cuenta con evaluaciones, las cuales forman parte del proceso de acreditación, en algunos casos y dependiendo del curso, será necesario entregar un proyecto de implementación</p>
                            <b></b>
                            <p>Tendrás una sesión con un coach y diferentes integrantes que hayan tomado la misma capacitación, este taller será a distancia, la finalidad es poner al alcance una persona experimentada en el tema no solo teorica.</p>
                            <b></b>
                            <p>Una vez llegado a este punto tu proceso de aprendizaje habrá sido evaluado y recibirás el certificado de acreditación, y si así lo deseas recibirás un reconocimiento publico en nuestras redes sociales, el registro de tu certificado podrá ser verificado en nuestra base de datos publica</p>



                        </div>

                    </div>
                </div>
            </div>
        </div>
        -->
        <Footer />

    </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Tmtmodel from './Tmtmodel';
import Courses from './Courses';

export default {
 components:{
     Header,
     Footer,
     Tmtmodel,
     Courses

 },
  name: 'Home',
  data() {
      return {
          profile: this.$store.getters.getUser
      }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bg-darkBlue{
    background: #152F44
}
.btn-black{ background:#000; color:#FFF; font-size:14px; border:1px solid #979797;}
.btn-black:hover{color:#FFF; background:#333232}
.btn-golden{ background:#AA8D5D; color:#FFF; font-size:14px; border:1px solid #9B7676;}
.btn-golden:hover{color:#FFF; background:#C4A167}


.carousel-caption {top:30%}
.carousel-caption h5{ margin-bottom:30px}
.section{margin:60px 0}
footer{margin-top:60px}
</style>
